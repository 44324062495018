/** @jsxImportSource @emotion/react */
import { useState } from 'react';

import { noScrollbarStyle, visuallyHidden } from '@common/cssUtilities';
import { sendGAEvent } from '@common/utlis';

type MultiElementTabProps = {
  children: React.ReactNode[];
  labels: string[];
  trackingParameters?: {
    container?: string;
  };
};

const MultiElementTab: React.FC<MultiElementTabProps> = ({ children, labels, trackingParameters }) => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <div>
      <div css={{ display: 'flex', justifyContent: 'center', margin: '0 -1rem' }}>
        <ul
          css={{
            display: 'flex',
            gap: '1rem',
            overflow: 'auto',
            padding: '0 1rem',
            ...noScrollbarStyle
          }}
        >
          {labels.map((label, index) => (
            <li
              key={label}
              css={(t) => ({
                cursor: 'default',
                opacity: 0.7,
                textTransform: 'uppercase',
                whiteSpace: 'nowrap',
                ...(index === activeTab && {
                  color: t.color.primary,
                  fontWeight: 700,
                  opacity: 1,
                  textDecoration: 'underline',
                  textDecorationThickness: '2px',
                  textUnderlineOffset: '3px'
                }),
                ':hover': {
                  color: t.color.primary,
                  opacity: 1
                }
              })}
              onClick={() => {
                setActiveTab(index);
                sendGAEvent({
                  category: 'Module Tracking',
                  action: 'Click',
                  label: `MultiElementTab - ${trackingParameters?.container} - ${label}`
                });
              }}
            >
              {label}
            </li>
          ))}
        </ul>
      </div>
      <ul css={{ marginTop: '2.5rem' }}>
        {children.map((child, index) =>
          index === activeTab ? (
            <li key={index}>{child}</li>
          ) : (
            <li key={index} css={visuallyHidden}>
              {child}
            </li>
          )
        )}
      </ul>
    </div>
  );
};

export default MultiElementTab;
export type { MultiElementTabProps };
