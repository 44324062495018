/** @jsxImportSource @emotion/react */
import { useMemo, useRef } from 'react';

import { useTrackModuleImpression } from '@common/tracking';
import Button, { type ButtonProps } from '@stories/atoms/Button';
import { SectionShort } from '@stories/atoms/Section/Section';
import Carousel from '@stories/organisms/Carousel';
import HitCompact from '@stories/organisms/HitListView/HitCompact';
import MultiElementTab from '@stories/organisms/MultiElementTab';
import { media } from '@stories/theming/settings';

import type { HitCompactProps } from '@stories/organisms/HitListView/HitCompact';
import type { THit } from '@vitafy/search-data-api-contracts';

export type THitDtoSubset = Pick<
  THit,
  | 'badges'
  | 'brand'
  | 'in_stock'
  | 'id'
  | 'image'
  | 'name'
  | 'prices'
  | 'url'
  | 'averageRating'
  | 'totalReviews'
  | 'product_options'
  | 'promo_text'
  | 'type'
>;

type CarouselComponent = {
  cta?: ButtonProps;
  products: Array<HitCompactProps>;
  trackingParameters?: { name?: string };
};

const CarouselComponent: React.FC<CarouselComponent> = ({ cta, products }) => {
  return (
    <>
      <Carousel seamless={true} slideGapCompensation={8}>
        {products?.map((product) => (
          <div
            key={product.id}
            css={{
              maxWidth: 'none',
              width: '236px',
              padding: '.5rem', // vertical padding account for overfloating tags
              [media('desktop')]: { width: '20%' }
            }}
          >
            <HitCompact {...product} />
          </div>
        ))}
      </Carousel>
      {cta && (
        <div css={{ marginTop: '1.5rem', textAlign: 'center' }}>
          <Button {...cta} cssProps={{ display: 'inline-block' }} />
        </div>
      )}
    </>
  );
};

const ctaProps: ButtonProps = {
  href: '',
  label: 'Alle anzeigen'
};

type CompactProductsCarouselProps = {
  cta?: ButtonProps;
  headline: string;
  productCategories: Array<{ href?: string; label: string; products: Array<HitCompactProps> }>;
};

const CompactProductsCarousel: React.FC<CompactProductsCarouselProps> = ({ headline, productCategories }) => {
  const containerRef = useRef(null);
  useTrackModuleImpression({ label: `CompactProductsCarousel - ${headline}`, ref: containerRef });
  const trackingParameters = useMemo(() => ({ name: `CompactProductsCarousel - ${headline}` }), [headline]);

  return (
    <SectionShort headline={headline}>
      <div ref={containerRef}>
        {productCategories.length === 1 ? (
          <CarouselComponent
            products={productCategories[0].products}
            cta={productCategories[0]?.href ? { ...ctaProps, href: productCategories[0].href } : undefined}
            trackingParameters={trackingParameters}
          />
        ) : (
          <MultiElementTab labels={productCategories.map(({ label }) => label)}>
            {productCategories.map(({ href, label, products }) => (
              <CarouselComponent
                products={products}
                key={label}
                cta={{ ...ctaProps, href }}
                trackingParameters={trackingParameters}
              />
            ))}
          </MultiElementTab>
        )}
      </div>
    </SectionShort>
  );
};

export default CompactProductsCarousel;
export type { CompactProductsCarouselProps };
